import React from 'react';
import './App.css';
import QRView from './routes/QRView';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import WaitingRoom from "./routes/WaitingRoom";
import Error from "./routes/Error";


function App() {
    return (
        <div className="App">
            <header className="App-header">
                <BrowserRouter>
                    <Routes>
                        <Route path={"qrview/:restaurantID"} element={<QRView></QRView>}></Route>
                        <Route path={"waitingroom/:customerUUID"} element={<WaitingRoom></WaitingRoom>}></Route>
                        <Route path={"error"} element={<Error></Error>}></Route>
                    </Routes>
                </BrowserRouter>
            </header>
        </div>
    );
}

export default App;
