import {EventStreamContentType, fetchEventSource} from "@microsoft/fetch-event-source";
import React, {useEffect, useState} from "react";
import {ReactComponent as BlobNotReady} from "../assets/blob_notready.svg";
import {ReactComponent as BlobAlmostReady} from "../assets/blob_almostready.svg";
import {ReactComponent as BlobReady} from "../assets/blob_ready.svg";
import {ReactComponent as BlobRemoved} from "../assets/blob_removed.svg";
import {useParams} from "react-router-dom";

export enum WaitState {
    NotReady = 1,
    AlmostReady,
    Ready,
    Removed,
    Finished
}

class RetriableError extends Error {
}

class FatalError extends Error {
}

function WaitingRoom() {
    let {customerUUID} = useParams<{ customerUUID: string }>();
    const [data, setState] = useState<{ waitingStateMessage: string, ready: WaitState, numberInQueue: string, averageWaitingTime: string, CustomizationData: Record<string, string> }>({
        waitingStateMessage: "",
        ready: WaitState.NotReady,
        numberInQueue: "-1",
        averageWaitingTime: "10",
        CustomizationData: {},
    })
    const handleStatusChange = (payload: string) => {
        const data: { WaitingStateMessage: string, NumberInQueue: string, AverageWaitingTime: string, CustomizationData: Record<string, string> } = JSON.parse(payload)
        console.log(data)
        setState({
                waitingStateMessage: data.WaitingStateMessage,
                ready: parseWaitState(data.WaitingStateMessage),
                numberInQueue: data.NumberInQueue,
                averageWaitingTime: data.AverageWaitingTime,
                CustomizationData: data.CustomizationData
            }
        )
    }
    useEffect(() => {
        fetchEventSource(`${process.env.REACT_APP_QBOX_DOMAIN}/api/subscribe/${customerUUID}`, {
            async onopen(response) {
                if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                    return; // everything's good
                } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                    // client-side errors are usually non-retriable:
                    throw new FatalError();
                } else {
                    throw new RetriableError();
                }
            },
            onmessage(msg) {
                // if the server emits an error message, throw an exception
                // so it gets handled by the onerror callback below:
                if (msg.event === 'FatalError') {
                    throw new FatalError(msg.data);
                }

                handleStatusChange(msg.data)
                console.log(msg)
            },
            onclose() {
                // if the server closes the connection unexpectedly, retry:
                throw new RetriableError();
            },
            onerror(err) {
                if (err instanceof FatalError) {
                    throw err; // rethrow to stop the operation
                } else {
                    // do nothing to automatically retry. You can also
                    // return a specific retry interval here.
                }
            }
        });
    }, [customerUUID])
    let logoURL = ""
    try {
        logoURL = data.CustomizationData["logo_url"]
    } catch (e) {
        //
    }

    const {
        textToDisplay,
        blob
    } = selectBlobAndText(data.ready, data.CustomizationData[data.waitingStateMessage], logoURL);

    function displayTimePretty(averageWaitingTime: number): string {
        let minutes = Math.floor(averageWaitingTime / 60);
        if (minutes < 1) {
            return " < 1 min"
        }
        return ` ${minutes} min`
    }

    const waitingTimeDiv = <p>There are {data.numberInQueue} people before you in the queue <br></br>Estimated waiting
        time
        is: {displayTimePretty(data.averageWaitingTime as unknown as number)}</p>

    function displayWaitingTimeIfAvailable() {
        if ((data.numberInQueue as unknown as number) > -1 && data.ready !== WaitState.Removed && data.ready !== WaitState.Finished && data.ready !== WaitState.Ready) {
            return waitingTimeDiv
        }
        return
    }

    return <div className='relative'>
        <h1>{data.CustomizationData["restaurant_name"]}</h1>
        {blob}
        <h2 className={'blob-text'}>{textToDisplay}</h2>
        {displayWaitingTimeIfAvailable()}
        <p>ID: {getShortID(customerUUID)}</p>
    </div>
}

function selectBlobAndText(state: WaitState, text: string, imageLink: string = "") {
    var textToDisplay = text
    let blob = <BlobNotReady className={'App-logo'}/>
    let style = makeStyle( "#9EF0F0")
    //#5cc940
    //If imagelink is not nil, we will use that one instead.

    console.log(state)
    switch (state) {
        case WaitState.AlmostReady:
            //textToDisplay = "Almost ready! Time to start moving towards the desk"
            blob = <BlobAlmostReady className={'App-logo'}/>
            style = makeStyle( "#F1C21B")
            break
        case WaitState.Ready:
            //textToDisplay = "Pick up your order at the desk!"
            blob = <BlobReady className={'App-logo'}></BlobReady>
            style = makeStyle("#5cc940")
            break
        case WaitState.Finished:
            //textToDisplay = `${text} 😋🍲`//"Hope you like your order! 😋🍲"
            blob = <BlobReady className={'App-logo'}></BlobReady>
            style = <></>
            break
        case WaitState.Removed:
            //textToDisplay = `${text} 🤷🔍` //"Oh no! You were removed from the queue because we didn't see you at the desk in time.  🤷🔍"
            blob = <BlobRemoved className={'App-logo'}></BlobRemoved>
            style = makeStyle("#80857d")
            break
    }
    if (imageLink !== "") {
        blob = <div className="waiting-image">

            <div className="avatar">
                <img src={imageLink} width="130px"
                     height="130px" alt="Company logo"/>
            </div>
            {style}
        </div>
    }
    return {textToDisplay, blob};
}
function makeStyle(color: string) {
    return (<style dangerouslySetInnerHTML={{
        __html: [
            '.avatar::before,.avatar::after{',
            ` border: ${color} solid 8px;`,
            '}'
        ].join('\n')
    }}/>)
}
function getShortID(connectionID: string | undefined): string {
    if (connectionID === undefined) {
        return ""
    }
    return connectionID.substring(0, 1) + connectionID.substring(24, 25)
}

function parseWaitState(s: string): WaitState {
    switch (s) {
        case "NOT_READY":
            return WaitState.NotReady
        case "ALMOST_READY":
            return WaitState.AlmostReady
        case "READY":
            return WaitState.Ready
        case "PERMANENTLY_REMOVED":
            return WaitState.Removed
        case "FINISHED":
            return WaitState.Finished
        default:
            return WaitState.NotReady
    }

}

export default WaitingRoom
