import React from "react";
import QRCode from "react-qr-code";
import {useParams} from "react-router-dom";


type IQRCode = {
    qrValue: string
}

class QRView extends React.Component<any, IQRCode> {
    restaurantQRID: string

    constructor(props: any) {
        super(props);
        this.restaurantQRID = props.params.restaurantID
        this.state = {qrValue: `${process.env.REACT_APP_QBOX_DOMAIN}/waitingroom/${this.restaurantQRID}`};
        if (process.env.NODE_ENV !== "production"){
            console.log("QR Link: ", this.state)
        }
    }
    render() {
            return (

                <div className="QR-Container gradient-border">
                    <div className="QR-Container">
                        <QRCode value={this.state.qrValue}/>
                    </div>
                    {process.env.NODE_ENV !== "production" && <a href={this.state.qrValue}>QR</a>}

                </div>
            )
    }
}

export default (props: Readonly<any>) => (
    <QRView
        {...props}
        params={useParams()}
    />
);

